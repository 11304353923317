import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Karma = () => {
  return (
    <Layout>
      <Head title="Black Market Karma" />
      <h3>Black Market Karma</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        London
      </h4>
      <p>
        <OutboundLink href="https://twitter.com/blackmarketkarm">
          Twitter
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://open.spotify.com/artist/1lbkSAoynPSO7OQOFSOdon#menu">
          Spotify
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.youtube.com/c/flowerpowerrecordstv">
          YouTube
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.instagram.com/blackmarketkarma/">
          Instagram
        </OutboundLink>
      </p>

      <h3>Interview by Edward Alvarez</h3>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/kPDtn93bTJQ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <p>
        I recently had the opportunity to speak with Stanley Belton from the
        band Black Market Karma. He is an absolutely prolific musician. Black
        Market Karma are due to release their 9th album this month. Aped Flair
        and Hijacked Ideas will not disappoint, it is a tremendous addition to
        their musical legacy. Stan talked about everything from the band's
        journey, to the magic of making music with his brother, and shared some
        of his most profound artistic influences.
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1662445168/artnow/black%20market%20karma/455552.jpg"
        alt="Black Market Karma London music by Jenn Cliff-Wilcock"
        style={{ marginBottom: 0 }}
      />
      <figcaption>photograph by Jenn Cliff-Wilcock</figcaption>

      <p>
        Edward Alvarez: Alright ok, now it's recording...So let's just take it
        from the top. I'm here with Stanley Belton from the band Black Market
        Karma. I really appreciate you giving me the chance to talk to you for a
        minute. And I hate to make you repeat yourself or anything...you had
        just finished explaining the meaning of name of the band, but I wasn't
        recording at the time, my bad. If you want to just kind of go through it
        again one more time, sorry about that.
      </p>

      <p>
        Stan Belton: Yeah, the idea is, when we started out, we don't have any
        rich family connections, or any celebrity friends, or any sort of
        connections to the business in any way, we don't have any kind of leg up
        like that. Our approach when we first started, and I was very young when
        I named the band, I was probably about 17. Our approach was to just give
        our albums away for free, online on our own website. And I kind of
        thought, Black Market Karma, was like, we were kind of, I guess trying
        to create good karma for ourselves, by just giving our music away, and
        the idea of it being free it would just peak people's interest, and like
        what have they got to lose but to give us a go, you know? Because, like
        I say we didn't have no way of having the massive promotion that people
        get. So that was our kind of tactic, and I thought, the way that it's an
        underground thing, it's kind of like a black market, except instead of
        having negative connotations, it's like a positive version of that, you
        know? Black market karma, that phrase just kind of I felt like
        encapsulated that in a band name. That was sort of I think, that's where
        I was coming from you know, it was a long time ago but that was the gist
        of it. That's why the logo is a guy with a parka and he's got records
        inside his sleeves, it's like he's dealing music on the down low.
      </p>

      <p>
        EA: Oh ok, right, right. Yea, it was really interesting that you
        were...I guess your first album you gave it out for free, I think like
        your first few albums, or was it just one album?
      </p>

      <p>
        SB: I'm trying to remember when we stopped doing that. I think it was
        maybe the first seven or eight records. I mean you could buy them too
        and people did. Some of them were on vinyl and things like that and you
        could buy them digitally and there were cds made at one point. So people
        would still buy them. But giving them away for free all we found it did
        really was draw people to the music, it didn't really harm us in any
        way, I don't think it did.
      </p>

      <p>
        EA: Yea, well that was actually kind of how I think I found your music.
        And I'll just say that I've actually been listening to your music since
        you guys started, which was, I think you guys have been doing music for
        like a decade, right? It's been like about 10 years?
      </p>

      <p>
        SB: Yea, the first record when we started giving this stuff away was
        2012...I mean I was doing stuff before that but I didn't finish a record
        until then, so I kind of see that as the real start of the band.
      </p>

      <p>
        EA: Well...yea, I've been a fan of you since the beginning. I think one
        of my favorite songs was from Comatose, I think it's called All These
        Things.
      </p>

      <p>SB: Cool man, nice choice man. </p>

      <p>
        EA: It was like something about that song, like I didn't even really
        know what the lyrics were but I would sing it to myself, like the melody
        or something...I guess what I'm trying to say is you've been able to
        consistently make great music for a long time. And it seems like that's
        kind of hard to do...that one song really got stuck in my head. But yea
        I remember you were doing the free music thing...What can you tell me
        about the early days of the band, it was just you and some friends?
      </p>

      <p>
        SB: If you're going back to the very beginning, everyone that was in the
        band in those days isn't in the band anymore. We've had a lot of
        different members. It stayed pretty solid for the past few years, like
        one or two changes...I was recording all of that, I mean I still do.
        Apart from a handful of songs, I record everything alone. Which I
        started doing way back on the first album. So I guess even though
        there's been a lot of changes studio wise, it has stayed consistent
        because it's mostly just me. So those early days...I was getting to
        grips with how to record myself, in like 2011 that's when I really
        started to delve into it. So those first few records are just kind of me
        sort of playing it by ear and like fumbling my way through it you know
        and just kind of finding out how to get the sound out of my head onto a
        record. And I liked the idea of just recording everything in order and
        releasing it as I made it...And I feel like it's worked. If you listen
        back on the discography now, in order, it's kind of a gradual
        progression...I guess it's like a stream of consciousness, and I was
        just kind of making a document of it, that's the best way I can explain.
      </p>

      <p>
        EA: Yea, I was looking through the discography and it seems like every
        year you were just making an album, it was pretty crazy.
      </p>

      <p>
        SB: I put four out in 2012 when we first started...I did like one or two
        for the next few years after that and then we stopped releasing them for
        a while, because I didn't want to give them away anymore, I wanted to
        find a different way to do it. But I continued to record at the same
        rate, maybe more. So now this new album that's about to come out is just
        one of a massive collection of music that I haven't shared yet, because
        I just carried on working, you know. So it feels good to start releasing
        some of that. I'm hoping to get all of it out there eventually. Off the
        top of my head I think there's about seven records of stuff that people
        haven't heard yet and there's a bunch more in my head that I want to
        record.
      </p>

      <p>
        EA: Oh, so you have more albums that you could already make that you
        haven't released yet? Is that what you're saying?
      </p>

      <p>
        SB: They're all mastered and recorded, but it was like, because I just
        need to work, but I wasn't releasing them because I don't know, I didn't
        want to just keep giving them away anymore. They're all finished but
        it's like I can't drop them all at once, it will just like fry people's
        brains, you know?
      </p>

      <p>
        EA: Wow...Yea that's intense...it seems like you have this natural
        talent for music, and I mean, that seems great.
      </p>

      <p>
        SB: I guess man, I can't really explain it. I love to do it, I guess I'm
        a bit obsessed. I can't explain it, there's like a rush every time you
        finish something new that you're happy with and you hear it back. It's
        like a feeling that's unlike anything else and you kind of get hooked on
        it you know and you want to keep making stuff. An addiction, I guess
        it's somewhat healthy.
      </p>

      <p>
        EA: No, well it's a passion, it's what you're passionate about...And I
        think it's like your purpose in life.
      </p>

      <p>
        SB: Purpose is a good word for it man...I don't know where I'd be if I
        wasn't doing it you know, probably a mess.
      </p>

      <p>EA: I wanted to ask you, were you inspired by John Lennon? </p>

      <p>
        SB: I would say so. Yea I definitely got into The Beatles quite heavily
        at one point.
      </p>

      <p>EA: I was watching this music video of yours...</p>

      <p>(technical delay)</p>

      <p>SB: I was saying I was about 16. </p>

      <p>EA: Okay so you kind of grew up listening to him. </p>

      <p>
        SB: Yea, I got into The Beatles, I got into The Velvet Underground, Bob
        Dylan, Johnny Cash, a lot of that stuff kind of blew my mind when I was
        very young and kind of set me on the path to what I do now. So I'd say
        The Beatles was definitely one of those.{" "}
      </p>

      <p>
        EA: Yea, I was watching this video of you last night…You kind of look
        like John Lennon a little bit. When you're wearing glasses, like the
        circle glasses.{" "}
      </p>

      <p>
        SB: I had these like art deco, like plastic clear glasses. I left them
        in Paris when I moved back...I missed those glasses, they were wicked.
      </p>

      <p>
        EA: Were you influenced by some of the other bands from the 60s? Like
        The Doors or stuff like that?
      </p>

      <p>
        SB: I listened to a lot of that stuff but in terms of like directly
        influencing how we sound, I would say The Velvet Underground and The
        Beatles is two of the biggest 60s ones, and The Troggs actually...The
        way they do these kind of poppy melodies but it's super dirty you know,
        like heavy bass and like kind of twangy, rough guitars. That definitely
        influenced me and Dylan had a big effect on me...But there's loads of
        odd tracks here and there that I always find from the 60s...
      </p>
      <p>
        EA: Ok, so we left off talking about...I was trying to ask you, what's
        your approach to music? Do you start off trying to make a classic song,
        or is it more about just expressing something? Or is it about like
        capturing a moment in time or something like that?
      </p>

      <p>
        SB: I think it'd be all those things man. I try to just let the song
        kind of come and let it dictate how it should be put together, rather
        than trying to force my ideas onto a song, if you know what I mean. I
        know it might sound like esoteric or something but it's like, I try to
        just sort of...service the song as it comes, you know? I can't really
        explain it. They just kind of, they just come you know, and then when
        they do you gotta be there for it and then just try and put it together
        in the way that maximizes its effect I guess. And don't try and like
        fight it and force some certain kind of production onto it if it's not
        going to work, do you know what I mean? I try to just be sincere and
        like trust the song and do what services the song the best. It's the
        best way I can explain it, so it's kind of capturing a moment I guess,
        because if you're being honest, I try to just be sincere in what I'm
        feeling you know, I guess it's all just like, it's like keeping a diary
        I guess.{" "}
      </p>

      <p>
        EA: So I guess you're kind of talking about intuition, like what feels
        right and stuff.
      </p>

      <p>
        SB: Yeah, I just try and like let myself sort of be taken into it and
        don't fight it, and it usually turns out better that way you know, I
        don't think about it too much.
      </p>

      <p>
        EA: Nice. Yea, that makes sense...It's a genuine thing...I don't know, I
        guess maybe that's potentially what classic songs are...some sort of
        sincerity or something.
      </p>

      <p>
        SB: Sincerity to me is just like the main thing, if you're being sincere
        you can't really go wrong I guess...I'm honest you know and I let the
        rest come as it comes.{" "}
      </p>

      <p>
        EA: The first two things that you released were EPs right? One of them
        was Take What You Get in 2008.{" "}
      </p>

      <p>SB: Yea, that was a long time ago.</p>

      <p>EA: Is it like you weren't too proud of it?</p>

      <p>
        SB: I can't listen to that stuff anymore. I was just 17 I think when I
        made that, I mean, but it doesn't matter. I didn't really know how to
        achieve what I wanted to do you know, it's like a slow process I guess.
        You need to like teach yourself the tools to be able to get them across
        how you hear them you know.{" "}
      </p>

      <p>
        EA: I remember there was this music video that you guys made that was in
        a French church. Do you remember that?{" "}
      </p>

      <p>SB: Yea man, yea.</p>

      <p>
        EA: And you guys were all playing this song...was there a story to that?
        I can't find that video anymore but I remember it was like really, it
        was incredible.
      </p>

      <p>
        SB: We played a festival in France, I can't remember where it was, it
        was in the chateau of like a really old castle and they had this like
        altar inside in this kind of tiny chapel and they were like you should
        come and film a song in here because it sounds great so that's how that
        happened. I must have been about 19 or something man. Yeah I remember
        that pretty clearly and then I think it was that night, or the night
        after, we played in a crypt like beneath the chateau and they actually
        found a skeleton in there. Because they'd never fully cleared it out,
        they wanted to clear out everything in there to make space for this gig
        and they found this, like you know really, really old skeleton. I don't
        know what become of that...it was a really old building.
      </p>

      <p>
        EA: Yea, I wish I would have made a copy of that or something, I
        remember I found it on youtube and it was just like, it totally like
        blew me away.
      </p>

      <p>
        SB: I'm going to get it to you man, I'm sure I can find it somewhere.
      </p>

      <p>
        EA: Ok so let's talk about your new album, Aped Flair and Hijacked
        Ideas.
      </p>

      <p>SB: What do you want to know? </p>

      <p>
        EA: I've been listening to it...The first song was really good, Dead
        Trajectory. And it's kind of just like the same thing with your other
        song, where it kind of got stuck in my head and then I was kind of like
        humming it to myself.
      </p>

      <p>SB: Good man, so it's working correctly.</p>

      <p>
        EA: Is there like a story to that song, Dead Trajectory, is it like
        maybe like a commentary about life, or something like that?
      </p>

      <p>
        SB: That song is me voicing some of my frustrations with how I found the
        music business to be. How it's about who you know, not what you can do.
        I mean, you can hear that in the words. It's also about kind of taking a
        risk to have a go at something that you want to do, that could maybe go
        tits up you know, that's kind of what that's about. Like...just jumping
        off the deep end and going for something.{" "}
      </p>

      <p>
        EA: What about Hijacked Ideas, I thought that was interesting. I was
        going to ask you, how do you feel about people being inspired by your
        music and trying to learn the songs, is that kind of like what Hijacked
        Ideas refers to or is it something else?
      </p>

      <p>
        SB: That title of the album is kind of a tongue-in-cheek, sort of like
        self-deprecating jab at like the idea that nothing is truly original
        because, I kind of see a lot of people get really hung up on some idea
        of trying to find some total originality which I just don't think
        exists. I think it's like the unicorn of the art world, you know. So
        it's this kind of a playful, it's almost like saying this album is just
        full of stuff I've stolen, which isn't true, but it's kind of a, it's a
        tongue-in-cheek, it's kind of a joke. I think everyone should be honest
        about their influences and what inspires them because everything is,
        everyone's influenced, everything connects to something else. It's kind
        of just human nature, and I think it's a beautiful thing and I think
        people should embrace it instead of pretending that, you know, they
        haven't been influenced, do you know what I mean?
      </p>

      <p>
        EA: It kind of reminds me of, Bob Dylan said he would kind of take stuff
        from other people's songs. So I was a little bit off with my
        interpretation. But I think what you're saying makes sense.
      </p>

      <p>
        SB: The cool stuff about with this kind of thing is you know, if someone
        interprets it a different way it's just as valid you know, that's what's
        great about art and music I think if you get something different from it
        that's great. Maybe I shouldn't say what I think it's about, you know,
        maybe I should just stay quiet.
      </p>

      <p>
        EA: That kind of ties into another question that I'm going to ask you
        later. What about Aped Flair? What is that? I think there's like a sloth
        on the cover of the album?
      </p>

      <p>
        SB: That's my dog in a space suit. He died a few years ago man, the last
        track is a tribute to him. So I wanted to put him on the artwork,
        because he was such a Zen dog. He was the calmest animal, and anywhere
        he went he just spread serenity...I mean it's like I aim to be like,
        learn something from him, that's always in there. But Aped Flair
        is...like a phrase, like you would say that someone has flair if they
        have like interesting ideas or a style, you know. And yea like, have you
        ever heard of the phrase to ape someone? Like to copy someone? It's like
        monkey see, monkey do. I think it's a 60s, you would say like someone is
        aping someone, if they're kind of copying them. It's like copying
        someone else's style. Again it comes back to the, it's like a
        self-deprecating joke, you see what I mean? It's kind of a play on
        words, I thought it was fun.
      </p>

      <p>
        EA: It definitely made me think and wonder and like you said everybody
        can interpret it different ways and stuff. But anyway, all I'm trying to
        say is the new album is really good. I've been listening to some of
        it...and I guess it's like, am I just saying that it's really good? Or
        do I really think that it's really good? Like for example, you know
        sometimes you want to be supportive and you tell somebody like, it's
        good or whatever. But I actually enjoyed, I really enjoyed listening to
        it. Pretty much every song on the album was great. I really liked the
        song Kong.
      </p>

      <p>
        SB: Yea, cool. I wrote that one with my brother, my little brother. I
        mean most of my stuff is just me on my own, but there's two songs on
        this album that were co-written with my brother and that was one of
        them. I was playing that drum beat one day and he picked the guitar up
        and he started playing those chords and then I wrote some lyrics and a
        melody to it, and we kind of frankensteined it together and it became
        Kong.{" "}
      </p>

      <p>
        EA: I was going to ask you a question about your brother, he's in the
        band right?
      </p>

      <p>SB: Yea, he plays guitar. </p>

      <p>
        EA: Do you think maybe you might attribute some of your success with
        music to being able to play music with your brother?{" "}
      </p>

      <p>
        SB: I feel grateful that we can do it. When we play a show and it's a
        good show, you know what it's like when you go to a gig that's got a
        good atmosphere...It's like a magic, transportive kind of thing...and to
        be able to look across the stage in that moment and have my best mates
        and my little brother with me, it's pretty special.{" "}
      </p>

      <p>
        EA: Yea, I guess that's a good way to put it, you're grateful about it.
        I guess it is a really valuable thing to have. I kind of wish my
        brothers were more involved with music. Maybe it's not so much luck but
        you have that gratefulness and that's meaningful.
      </p>
      <p>
        SB: Yea, I guess I'm lucky that we have similar tastes so that we can
        connect on music you know, which I guess that that's one of the reasons
        it works because, you know, I show him the songs that I've written. You
        see, he's not like yea, yea it's good. He gets it because he likes the
        same stuff, so he's passionate when he hears it. And he enjoys it which
        I guess is, sometimes siblings can be so different... I could play my
        brother my music and he could be into, he could be into like trap music
        and just not enjoy it at all, so I'm lucky that we're on a similar page
        like sonically that he can enjoy.
      </p>

      <p>
        EA: Yea, it's amazing. Maybe you could explain, how long were you
        working on the album? Maybe you can talk about it a little bit?
      </p>

      <p>
        SB: I probably made it in about half a year or maybe a bit less...it was
        a long time ago. Like I said I continued to make stuff. We made this a
        long time ago, in my old studio which had no windows, you know I would
        just shut myself in the soundproof box for like days on end making
        songs. So they've all been made like that, so it becomes kind of a blur,
        you know. But I felt like I was getting into some kind of like, a really
        colorful, almost kind of a quiet like beachy sort of sound. I've had a
        few people pick up on that, I think that definitely has come across...
        but as far as the process, it's just me, you know, in a room. I did
        those two songs with my brother and the rest is just me you know playing
        the drums, getting that tight, adding the bass, figuring out how I want
        to arrange it you know, doing keyboards, guitars, and just slowly
        stitching it all together. That's how I've done everything, and how I
        continue to do stuff. So, it's not like a special process that's
        different to the others.
      </p>

      <p>
        EA: I definitely got like a summer vibe from it. I don't know if that's
        what you were going for but it sounds like other people have told you
        that.{" "}
      </p>

      <p>
        SB: Yea, it does sound summery. Sometimes I don't even know what I'm
        going for, like I say I'm just trying to do what I think suits the song
        and I guess these songs are summery, so I've found myself putting
        guitars and keyboards on them- they're like super kind of watery
        sounding, like you know they sound like a coral reef or something. I
        guess so, yea...a summer album which is coming out at the end of summer.{" "}
      </p>

      <p>
        EA: Do you have a favorite song on the album? Is that the way you think
        about things, or is it like you like everything?{" "}
      </p>

      <p>
        SB: If I don't think something is good enough I won't bother finishing
        it you know.
      </p>

      <p>
        EA: I liked Cadet #2187, is that like maybe like a space theme or
        something?
      </p>

      <p>
        SB: Yeah well if you listen to the words, it's kind of the idea of like,
        I'm kind of fed up with fake kind of cronyism and you know this
        sentiment is like let's leave the planet and go somewhere else, which I
        guess is kind of a way of just explaining like how we approach our
        music...I've always recorded myself, we've self-produced,
        self-released...we kind of like to make our own place in our own little
        world and I guess that those lyrics sort of reflect that, and I just
        wanted, I like the idea of giving it a number, like you're part of, I
        don't know, a mission or something. And 2187 is the cell block of
        Princess Leia in A New Hope, which I thought was a fun reference. I
        don't know if anyone will pick up on that.
      </p>

      <p>
        EA: Do you consider yourself famous? Do you think that you're a famous
        musician?
      </p>

      <p>
        SB: I don't know, I wouldn't say so. I don't know, I don't really think
        about it in that term. I'm just trying to make the best stuff I can, be
        sincere and reach as many people as I can. Really I don't know, I have
        no comment on that. What is famous you know? I mean the Kardashians are
        famous but what for really? You know, so that's kind of how I see that.
      </p>

      <p>
        EA: Yea, that's a good point. Were you ever inspired by The Brian
        Jonestown Massacre?
      </p>

      <p>
        SB: Yea, man. They're one of the bands that I got into. Probably around
        the time I heard The Velvet Underground, I heard BJM and I got into
        Spacemen 3 as well...They all kind of influenced each other, there's
        like a string of bands from the 80s and 90s that have that sound, and to
        me it was like wow, there's people that are still alive that are doing
        music like The Velvet Underground, you know? That to me, those bands are
        almost like a continuation of that kind of spirit. So when I found that
        90s stuff like BJM and Spacemen 3...it was really exciting to me and
        Spiritualized. I think of those bands all as being together in a way.
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1662445168/artnow/black%20market%20karma/504165.jpg"
        alt="Black Market Karma band"
      />

      <p>
        EA: Yea, they had like just this whole jam type of style which was
        almost like infectious...but I mean, you also have your own original
        style too, so you're not exactly like them, you're different.
      </p>

      <p>SB: A big inspiration, I love that kind of music. </p>

      <p>
        EA: So kind of like building off of the last thing that I asked,
        sometimes I feel that there's a futility in music...I mean sometimes
        when you're playing guitar by yourself like it could feel a little bit
        hopeless and like maybe depressing...It's almost impossible to imagine
        making music and getting it out into the world and it being appreciated.
        Did you ever think that you would reach so many people?
      </p>

      <p>
        SB: Yea, I understand what you mean man...I still feel like that
        sometimes, I mean it's a very hard thing to pursue in terms of getting
        people to listen, you know? It's like if you just take the time to just
        give it a play you might like it, but it's getting people to. That's one
        of the reasons we gave our stuff for free at the start was because it
        just peaks someone's interest like what have they got to lose? Give it a
        go. But yeah I understand that hopeless feeling and sometimes I actually
        still feel like that, but then you know, that's not a good head space to
        be in so I snap myself out of it and…even when I'm gone it can still be
        appreciated basically forever. And there's always new people, I get
        people messaging me you know who find my music, and to them, they listen
        to music I made over 10 years ago, but to them it's brand new and that
        always keeps me going you know, that's kind of a magical thing that this
        music can be new to someone forever. Like kids are still discovering The
        Beatles, do you know what I mean? To someone it's still brand new and I
        think that's kind of, that's the magic of it, that kind of keeps me
        going.
      </p>

      <p>
        EA: What about like when you were first starting out, did you think that
        you would become as loved as you are now, or was it more like you
        weren't sure?
      </p>

      <p>
        SB: I mean for a few years it was just me trying to make stuff that I
        didn't listen back to and think this is shit. You know what I mean? That
        was the main focus, and then once I got a record together I could listen
        back to it somewhat objectively and actually enjoy it and be like yea
        I'm proud of this. Then I kind of felt more confident in the idea of
        other people enjoying it too. I mean you're your harshest critic...So I
        guess, once I was proud of something I could kind of, I could see
        someone else enjoying it, but I don't know man, like I said, I just
        tried to make the best music I can. If people enjoy yea, they don't, you
        know, that's fine as well.
      </p>

      <p>
        EA: Actually on that note I was just thinking, do you ever surprise
        yourself? In terms of like, maybe you record something but you don't
        really think much of it, but maybe you'll go back and listen to it and
        it's like really good or something?
      </p>

      <p>
        SB: Sometimes. Yea, you get a bit of distance from stuff and, if it's
        been a long enough time you can almost listen to it like if someone else
        is recorded because...when you're really close to recording something,
        you listen to it and you just picture like the drum pattern or like how
        you mixed it, you know? You picture like the mechanics of it because
        you're too close to it. When you've been away from it for a few years I
        think you can hear it a little bit more like someone else does. And
        sometimes I listen to stuff back and I'm like, yea I'm happy, I'm proud
        of that you know? I can kind of hear it with a bit of distance, does
        that make sense?
      </p>

      <p>
        EA: Yea, that makes sense. So you're also a multi-instrumentalist right?
        Like you play the sitar? You started playing sitar when you were in
        college or something?
      </p>

      <p>
        SB: Yea I got it for maybe my 17th birthday, 16th something like that. I
        had a rug for it, I used to take it to college. I'd sit on this rug and
        practice in the hallway and the other kids apparently dubbed me the
        "Ginger Wizard" but I never found out about that nickname until years
        later. I like you know The Beatles and Brian Jonestown Massacre and you
        know people that were doing that...I guess Brian Jones is credited with
        starting that 60s thing of merging Indian music with psychedelic rock.
        So I wanted to try and do something with that. I feel like maybe I did
        it well. I think that there's a very fine line with it becoming kind of
        cliché now you know, so I tried to do something with it that wasn't too
        cliché, like you know what I mean?
      </p>

      <p>
        EA: Yea...well you're great on sitar. I was listening to that one song
        the other night, Sitari-Wary from the Semper Fi album. And it was just
        like, it just mixed so well with the guitars and everything and…I
        actually tried to play a sitar a long time ago and I could never make
        sense of it, but somehow it makes sense to you and you could do it.
      </p>

      <p>
        SB: I'll tell you man, to put strings on it, to get it strung and in
        tune is a fucking nightmare but it's worth it...Like the maintenance is
        hard but, and also I'm not very flexible, so to sit in a proper position
        absolutely kills me man, but once you get it recorded and you get that
        sound, it's all worth it.
      </p>

      <p>
        EA: One of my favorite songs from Black Market Karma is Keep On Keeping
        Your Light On. I was wondering, is there a story to that song? Or is it
        just like you guys kind of just randomly made it?
      </p>

      <p>
        SB: Well um I guess it goes back to what you just asked me about feeling
        hopeless sometimes...Like are you ever gonna get people to listen, do
        you know what I mean? It doesn't have to be about music, you can kind of
        translate to anyone's pursuit in life that seems sort of against the
        odds. That's what that's about, you know. Hold your light and they will
        come kind of thing you know. If you, I believe, if you're sincere and
        you work hard, and you do something worthwhile, people will come to it
        eventually, it might take a long time. That's sort of what that one's
        about.
      </p>

      <p>
        EA: I would just like put that song on repeat and it was just like, it's
        so good. I mean...the same is true for a lot of your songs, you could
        just kind of listen to them over and over again. The other thing I was
        going to talk to you about is...Do you ever feel that 24 hours is not
        enough hours in the day to get everything done that you want to do? Do
        you have any thoughts on time? And do you ever feel like, you know
        sometimes we feel like we have all the time in the world. Is that the
        way that you feel? Or do you feel like you don't have enough time?
      </p>

      <p>
        SB: I would say ever since I was young I'm very conscious...that I'm not
        here forever. I think about that every day. I would say so, I'd say I'm
        quite conscious of time, probably too much. Something I try to work on,
        sits in the back of my mind a lot. But at the same time you know, I like
        my sleep. I need quite a lot of sleep, so well...I think I've recorded
        about 17 albums so I'd say I'm finding the time...I guess everyone says
        you need to be conscious of it, but also you know, it's good to just
        relax sometimes and kick back you know, not feel guilty that you're not
        being productive or something you know.
      </p>

      <p>
        EA: Have you ever struggled to finish a song? Do you ever have
        difficulties?
      </p>

      <p>
        SB: Um yeah I probably had a few songs, some are harder to finish than
        others. I usually find that happens if I don't finish it in the moment
        though. Sometimes I'll come up with an idea and then I'll kind of not
        finish it and do something else and then when I come back to it, because
        you've left it for a while it's a bit harder to get back into the swing
        of what that song was and get it finished. So I would say it's less of a
        struggle if you try and finish it as it comes, do you know what I mean?
        Making it hard to pick something back up sometimes, but otherwise no,
        not really, I usually try and see it through as it comes, and if it's
        too hard then it's probably not good enough, or I'm forcing it and I'll
        just leave it alone.
      </p>

      <p>EA: Do you think drums are necessary for a song to be complete? </p>

      <p>SB: No, I don't think so, it depends what kind of song you make.</p>

      <p>
        EA: What about guitar solos, what do you think about guitar solos? Kind
        of a dumb question.
      </p>

      <p>
        SB: I mean, I guess guitar solos helped me learn how to play guitar when
        I was really young, trying to learn like Blues lyrics and stuff. I don't
        really think of that. I mean, I've heard people say that to me before,
        they refer to a piece like a part that I might play in a song live and
        they call it the solo, but I don't really think of things like that
        anymore...I don't know how to explain this...I think the idea of the
        phrase "guitar solo" kind of like is putting all the importance on the
        guitar solo, if you know what I mean...I'd like to think of things more
        orchestrally almost like...kind of you know when a song really gets you
        and makes your hair stand on end it's probably not just because of the
        guitar solo you know, it's because of the whole thing. So what do I
        think of guitar solos, yea I don't know...
      </p>

      <p>
        EA: Do you have any advice for people who are struggling to keep going
        with an instrument? Like maybe somebody who's learning to play guitar?
      </p>

      <p>
        SB: I would say the key is to make sure you're learning something that
        you enjoy, you know. Because I know some people get lessons, which is
        fine. But they'll be learning something just because that's what they're
        being taught to learn but they might not necessarily feel really very
        passionate about it. Which I think, it's always going to make it harder.
        I had piano lessons when I was quite young and I was learning music that
        I pretty much had no connection to and I didn't get anywhere with it. I
        couldn't play it very well. And then I got the guitar and I taught
        myself guitar but I was learning to play songs that I really loved. So
        even when I was first beginning, I was like barely stringing together a
        song, I was much more excited, and it pushed me harder to learn because
        I was enjoying while I was hearing back you know, that would be my
        advice- try and make sure you're learning stuff you like.
      </p>

      <p>
        EA: Yea...Do you feel like you're always learning new things with music?
        Or do you feel like you have learned everything?
      </p>

      <p>
        SB: I think so, I think I'll always be learning something, yea. Even if
        it's just hearing new music and hearing the way something's done
        differently...and being inspired by it you know, you're always kind of
        discovering different angles.
      </p>

      <p>
        EA: Maybe we'll talk about UFOs for a second. Have you ever seen a UFO?
      </p>

      <p>
        SB: Um no, I saw something behind my house once that kind of hovered for
        a while and then moved off over the back of the houses but I don't know
        if it was a UFO or if it was someone just like messing around with a
        drone or something. It's kind of trippy though, that's the closest thing
        I've come to a bona fide you know crescent shaped object...I used to
        watch loads of UFO stuff when I was growing up, when I was a kid, I was
        obsessed man you know Area 51, conspiracy videos and all this kind of
        stuff.
      </p>

      <p>
        EA: Yea, I mean, it makes you wonder...I guess like, if anything it kind
        of makes you think about what's possible.
      </p>

      <p>
        SB: I wonder sometimes if it's some kind of natural phenomenon that we
        just don't have the capacity in science to explain yet...Or it's like
        something that's inter-dimensional or what, some kind of weird quantum
        physics anomaly, or something that we maybe could explain. I don't know
        whether I believe if it's like green men in a tin saucer or not...do you
        know what I mean? I wonder if it's kind of ethereal that one day we'll
        be able to explain.
      </p>

      <p>
        EA: Yea, I think Terence McKenna kind of said something about that, that
        he didn't think that they were actually um, I think he gave a lecture
        about it and he said it was probably something inside of our minds, that
        it's like we're creating them in our heads or something like that.
      </p>

      <p>
        SB: I can't explain this well, but I know that in quantum physics
        there's a, isn't it like matter behaves differently whether it's being
        perceived? Which I think is a proven thing, I don't know to what extent,
        but...that's kind of an amazing idea to me, maybe these phenomena are
        tied to that concept somehow. I don't know I'd have the vocabulary to
        explain that.
      </p>

      <p>
        EA: Yea, it's the quantum physics thing where the observer kind of
        changes things...it's like power of consciousness or something like
        that.
      </p>

      <p>
        SB: Yea, yea like sometimes I wonder if consciousness could be the
        primary thing and physical matter is actually secondary. I like that
        idea...but like I say I don't have the knowledge of the language to
        really articulate that...Near-death experiences, psychedelic
        experiences, UFOs, you know, bigfoot, strange disappearances, all these
        things. I wonder if they're all just a link to some kind of esoteric
        thing that hasn't been proven yet...
      </p>

      <p>
        EA: Yea...Oh, I forgot to ask you, on the corner of the new album it
        says number nine, does that mean anything?
      </p>

      <p>
        SB: Yea, it's the ninth album I recorded. I've actually released one in
        between I made after, so it's become the tenth, but in terms of
        chronologically making records it's the ninth album...
      </p>

      <p>EA: Do you think that you're a musical genius?</p>

      <p>
        SB: That's up to other people to decide...Yea, no comment...Someone like
        Ennio Morricone maybe is a musical genius.
      </p>

      <p>
        EA: Well I mean, I guess it's good to be humble, that's always like a
        good quality...Maybe you are some sort of musical genius or I mean, I
        feel like, well from my perspective, because I'm not constantly making
        music. I feel like I really struggle to play guitar sometimes. So kind
        of seeing everything that you're doing I'm like okay this guy's some
        sort of genius you know?
      </p>

      <p>
        SB: Well it's the same way I would look at someone like a master
        carpenter or something and it just blows me away, someone like carving a
        violin out of wood with beautiful details. I've got a friend who's a
        stone mason and some of the stuff he can do with his hands you know,
        it's just like wow, how does he do that? I guess maybe people see me
        playing a couple of chords on a guitar it's the same thing to them. It's
        all perspective, I guess when you hear something every day gets taken
        for granted a bit...it's always been something I've just found quite
        easy, so everyone has different skills.
      </p>

      <p>
        EA: Yea...Oh can you talk really quick about, I guess like, yea you
        released some albums for free but like you also I think...this is your
        second album on vinyl...Are you planning to re-release your other albums
        on vinyl? Or is there anything going on with the vinyl stuff?
      </p>

      <p>
        SB: After this record we have other new stuff coming out on vinyl. I
        don't want to tell too much about it yet, but some of those are actually
        pressed, they already exist and uh some people have been offering to,
        we've been talking to people about some of our back catalog out as well
        so I think that will happen eventually. I think we'd like to go back to
        the beginning, maybe put Comatose out. The second EP and Comatose, I've
        actually re-recorded parts of it and remixed it and mastered it again,
        so it sounds the best it's sounded and I'd like to honor that with vinyl
        someday.
      </p>
      <p>
        EA: I guess you appreciate vinyl, like, do you listen to records and
        stuff?
      </p>

      <p>
        SB: Yea...there's something special about the tangibility of it you
        know, it just feels good to get the artwork up close, and it's in that
        big format. It's just a nice experience and people want it, so it's one
        way for us to make money that's substantial...Obviously everything's
        streaming nowadays which doesn't bring in anywhere near as much as a
        vinyl can, and you can't download a vinyl, so it's a good way to keep it
        going.
      </p>

      <p>
        EA: Yea...I guess it is an old technology but it still has a lot of life
        to it. I have a question that's kind of related to Velvet Underground
        I'll ask you really quick. So Velvet Underground has this demo version
        of Ride into the Sun and the lyrics say something like "looking for
        another place, somewhere else to be" and sometimes it sounds like he's
        saying "someone else to be" and maybe everybody feels that way
        sometimes, but is there something about living in London that made your
        music possible?
      </p>

      <p>
        SB: First of all, I know the version you're talking about. I absolutely
        love that song...Actually that inspired Cadet #2187 that was one of the
        inspirations, direct inspirations for that song...I love that demo. And
        living in London uh, I don't know really, yea maybe. Maybe it was good
        to be near a city starting out just to be able to play to people that
        like this kind of music but I've lived in a few different places now,
        I've lived in Paris, I've lived in London, I've moved down to the coast
        and I found it hasn't affected the way I make music really, I think I
        could make music anywhere to be honest.
      </p>

      <p>
        EA: Hmm...So yeah, we've been talking for a good while but I'll try to
        just kind of cruise through these last questions here so, I know you
        might have some things to do and stuff, so...Have you ever been to the
        United States?
      </p>

      <p>
        SB: Once when I was a kid, to Disneyland. Not the most enriching
        experience of American culture, definitely an aspect of it.
      </p>

      <p>
        EA: Do you think you would ever, are you planning to ever play a show
        over here or anything like that?
      </p>

      <p>
        SB: I would love to. I've had a few people asking us, so just depends on
        the logistics of it if we can make it work out. Yea...I'd love to come
        and play.
      </p>

      <p>
        EA: Nice...Do you have any formula that you follow for songwriting? My
        friend was telling me that there's this equation that he swears by, he
        says it's something that The Beatles used to do for songwriting. He said
        it's called a middle eight, have you heard of it?
      </p>

      <p>
        SB: I probably am following some sort of formula but I wouldn't be the
        person to be able to explain what that is. I'm very intuitive you know,
        like I taught myself how to play, I taught myself how to record, but I
        did a lot of it kind of by ear and by intuition, so I think I'm pretty
        accomplished at it, but when it comes to explaining it in technical
        terms or explaining it to someone else that's maybe classically trained,
        I'm like a space alien you know, I can't communicate it...
      </p>

      <p>
        EA: Well my friend's been going on about that stuff, so I was like, oh
        maybe I'll ask him about it or something, but yea I guess it's probably
        better not to get too technical. Do you ever get song ideas from your
        dreams?
      </p>

      <p>
        SB: I have dreamt songs before but then you wake up and you can't grasp
        it again, and it's the most frustrating thing. But I wonder if it's
        because when you're in a dream you're almost in like four dimensions you
        know, I don't know if something you dream can be translated into real
        life. So maybe you feel like you dreamt a great song but maybe you
        didn't. I don't know, I've never woken up and been able to remember the
        music to be able to then pursue making it. I have had the sensation that
        I dreamt of a great song, that's the only way I can put that.
      </p>

      <p>EA: But you remember your dreams? You have vivid dreams sometimes?</p>

      <p>
        SB: Sometimes yea. I remember them but they're always just batshit
        crazy. They have no structure and I just find them really hard to
        explain once I'm awake...Very rarely I have dreams that are kind of
        quite structured and I can remember them clearly when I wake up. They're
        normally just, it's completely crazy and really schizophrenic and
        they're all over the place and…I'm like wow, what was that? And I just
        remember like images and locations that are in them and kind of a
        general theme of what the dream was, but if someone was like, can you
        write it down in like a chronological order, explain how your dream
        went, I just wouldn't be able to. Do you ever have that sensation?
      </p>

      <p>
        EA: Dreams can definitely be disjointed and like kind of random, it's
        different all the time.
      </p>

      <p>
        SB: Or explain a dream to someone is almost like trying to make a really
        articulated point in a language that you can't speak very well once
        you're awake. It's kind of like that...like you're trying to have a
        conversation in French but you can't really speak French or something,
        that's kind of what trying to explain a dream after I've woken up, it's
        like I need to go back to sleep to make sense of it, because I think
        you're just in a whole different reality.
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1662445168/artnow/black%20market%20karma/277549.jpg"
        alt="Black Market Karma London rock music by Dan Briston"
        style={{ marginBottom: 0 }}
      />
      <figcaption>photograph by Dan Briston</figcaption>

      <p>
        EA: This is one of my last questions...So the pianist Chilly Gonzales
        wrote in one of his books, he said that to be a great artist you can't
        like everything and you have to have your own dislikes. He said
        something like, that he would roll his eyes when people say that they
        like all types of music. And so on that note I was wondering, what do
        you dislike musically? Are there any artists or music that you're not a
        fan of? And then the example that he gave in his book was that the
        pianist Igor Levit said that he hates Chopin. So is there maybe like a
        type of music that you hate? Or something that you're not a fan of?
      </p>

      <p>
        SB: I don't know. Like, super modern kind of American country, but you
        know, where it's just like bastardized country music and it sounds
        really bad and it's like super cheesy. I guess that's like one thing I
        just can't listen to...I could listen to old country music but, do you
        know the kind of, I don't know what that would be classed as, you know
        what I mean? That kind of like, really cheesy, like middle America
        modern country stuff? I just think it's terrible...I know it sounds like
        kind of a cop out, like I'm trying to be a hipster or something but I
        try not to think too much about genre. To me it's just good music is the
        genre. And I know that's subjective, but if I hear something and I think
        it's good you know, I listen to all kinds of stuff...So I try not to get
        too tied up in what genre I like...But I do agree, I think you need to
        know what you don't like to know what you like. If you don't know...if
        you're not sure of what you actually like or what you want to do, you're
        just going to be constantly, you're going to have no direction.
      </p>

      <p>
        EA: So you told me once that you don't do a lot of reading, but do you
        think the story of your band could ever be turned into a book or a
        movie?
      </p>

      <p>
        SB: Maybe if anyone's interested in it...Yea, I don't know, it's a nice
        idea. I'd like to make a documentary...just about my kind of musical
        process. So I guess that's a film in some way. I mean when I say that I
        don't read, I do you know. I'm always reading, I'm always learning and
        informing myself, and trying to discover new things and teach myself new
        skills, but I'm not really someone that sits and reads books. So when
        people say, "Do you read?" And I say no, I think I feel like that makes
        me sound kind of ignorant and dumb. But I do read, just like, it's more
        in a modern way. I'm always watching like youtube tutorials on how to
        teach myself new skills, which I guess is like a form of reading and
        learning...When I was younger I would read books but I'm not so much the
        kind of person to sit and read a book these days. I'm always trying to
        record a song or learn how to get a better drum sound or whatever it is
        you know, does that make sense? I like to inform myself and learn new
        things, I just wouldn't necessarily say I buy a lot of books.
      </p>

      <p>
        EA: I got everything that you said, and no worries. I mean everybody
        kind of goes at their own pace and stuff, and it seems like learning is
        still important to you. So…everybody learns in their own time, but I
        just thought maybe the story of the band is like some sort of adventure
        you know, over time or something like that. Could be like a good story
        or something.
      </p>

      <p>
        SB: Yea, well I mean I think there's still a lot to go yet, so maybe we
        have to wait a few more years and then come back to it.
      </p>

      <p>
        EA: I think maybe I'll let you go and we'll just end it here. I don't
        want to take up too much more of your time, plus I don't want more
        technical problems to happen so anyway, it was great talking to you and
        thanks again for answering some of these questions.
      </p>

      <p>SB: Of course... nice talking. Good questions, good chat. </p>

      <p>
        I later asked Stan if he would mind elaborating more on our previous
        discussion about personal interpretations, which I forgot to ask during
        the interview.
      </p>

      <p>
        EA: In the first season of the new Twilight Zone show, there's a story
        about how once you essentially give something out to the world it takes
        on a life of its own. The fictional character JC Wheeler says, "Put
        yourself out there and you will be successful. Once you put it out there
        the audience will take it in. And once it's theirs, it's gone forever."
      </p>

      <p>
        SB: Yeah I agree with that idea. Once it’s out the door it takes on a
        life of its own. It’s not really yours anymore. It means something to a
        lot of people. It’s a special thing. People have their own experiences
        with that thing whatever it is: a song, a film, etc. And if it reaches a
        lot of people, then those experiences become too numerous for you to
        know about. So it sort of gets away from being this isolated thing you
        once made.
      </p>

      <p>
        It seems Stanley Belton and his band Black Market Karma will continue to
        be successful for years to come. He genuinely loves what he does, and a
        lot of what he said during this interview really resonated with me. Aped
        Flair and Hijacked Ideas will be available September 29.
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1662445168/artnow/black%20market%20karma/385493.jpg"
        alt="Black Market Karma new album"
      />
    </Layout>
  );
};

export default Karma;
